<template>
	<div class="in-group">
		<div class="title">
			{{ $route.meta.title }}
			<i @click="showCourse = !showCourse" class="iconfont icon-question-circle"></i>
		</div>
		<div class="course" v-show="showCourse">
			<p><span class="black">1、客户通过扫描带参数的二维码，添加引流成员后，推送指定群聊入群二维码，更快让客户进入指定群获得精准运营。</span></p>
			<p><span class="black">2、可实时查看入群客户详情。</span></p>
		</div>
		<div class="inputs">
			<el-form :inline="true" :model="searchForm" class="demo-form-inline">
				<el-form-item label="入群二维码名称"><el-input size="small" maxlength="20" v-model="searchForm.name" placeholder="请输入"></el-input></el-form-item>
				<el-form-item><el-button size="small" class="btn-blue" @click="onSearch">查询</el-button></el-form-item>
			</el-form>
		</div>
		<div class="btns" style="margin-top: 15px">
			<el-button size="small" :class="selectList.length == 0 ? 'btn-opacity' : 'btn-white'" :disabled="selectList.length == 0" @click="handleBatchDownload">
				批量下载
			</el-button>
			<el-button size="small" class="btn-blue right" @click="handleCreatQrcode">新建入群码</el-button>
		</div>
		<div class="table" style="margin-top: 15px">
			<el-table
				v-loading="loading1"
				ref="Table"
				:data="tableData"
				tooltip-effect="dark"
				style="width: 100%"
				:header-cell-style="{
					color: '#333',
					fontSize: '14px',
					backgroundColor: '#F6F7FB',
					fontWeight: 'normal'
				}"
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="qr_code" label="入群二维码">
					<template slot-scope="scope">
						<el-image
							fit="cover"
							:z-index="9999"
							style="width: 100px; height: 100px"
							:src="scope.row.qr_code"
							@click.stop="$closeImg"
							:preview-src-list="[scope.row.qr_code]"
						></el-image>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="入群码名称"></el-table-column>
				<el-table-column prop="customer_group_name" label="关联群聊" width="200px">
					<template slot-scope="scope">
						<el-tag effect="plain" v-for="(item, index) in scope.row.customer_group_name" :key="index">{{ item.name }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="code_type" label="类型"></el-table-column>
				<el-table-column label="成员/入群客户">
					<template slot-scope="scope">
						<el-button type="text" @click="handleDetail(scope.row.id)">{{ scope.row.number[0].staff }}/{{ scope.row.number[0].customer }}</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="is_add_friends" label="添加验证"></el-table-column>
				<el-table-column prop="create_time" label="标签" width="300px">
					<template slot-scope="scope">
						<el-tag v-for="(item, index) in scope.row.tag_name" :key="index">{{ item }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div class="flex-btns">
							<el-button type="text" @click="handleEditQrcode(scope.row.id)">编辑</el-button>
							<el-button type="text" @click="handleDel(scope.row)">删除</el-button>
							<el-button type="text" @click="downloadIamge(scope.row.qr_code, scope.row.name)">下载</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination" v-if="total !== 0">
			<el-pagination
				@size-change="handleLimitChange"
				@current-change="handlePageChange"
				:current-page="page"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="limit"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div>
		<!-- 入群详情 -->
		<el-dialog title="入群详情" :visible.sync="detailVisible" width="800px" :before-close="beforeClose" :close-on-click-modal="false">
			<div style="margin-top: -20px" class="loading" v-loading="loading2">
				<el-tabs v-model="tabNum" @tab-click="handleCheckTab">
					<el-tab-pane v-for="(item, index) in groupList" :key="index" :label="item.name" :name="item.id">
						<div class="table" style="margin-top: 15px">
							<el-table
								v-loading="loading3"
								height="450px"
								:data="tableData1"
								tooltip-effect="dark"
								style="width: 100%"
								:header-cell-style="{
									color: '#333',
									fontSize: '14px',
									backgroundColor: '#F6F7FB',
									fontWeight: 'normal'
								}"
							>
								<el-table-column prop="name" label="群成员">
									<template slot-scope="scope">
										{{ scope.row.name || '--' }}
									</template>
								</el-table-column>
								<el-table-column prop="user_type" label="成员类型"></el-table-column>
								<el-table-column prop="join_scene" label="进群方式"></el-table-column>
								<el-table-column prop="invitor" label="邀请者"></el-table-column>
								<el-table-column prop="join_time" label="进群时间"></el-table-column>
							</el-table>
						</div>
						<div class="pagination" style="margin: 30px 0 0px" v-if="total1 !== 0">
							<el-pagination
								@current-change="
									page => {
										page1 = page;
									}
								"
								:current-page="page1"
								:page-size="limit1"
								layout="total, prev, pager, next, jumper"
								:total="total1"
							></el-pagination>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<span slot="footer" class="dialog-footer"><el-button size="small" class="btn-blue" @click="detailVisible = false">关 闭</el-button></span>
		</el-dialog>
	</div>
</template>

<script>
import { reqAutoCustomGroupList, reqGetCustomGroupInfo, reqAutoCustomGroupInfo, reqDelAutoGroup } from '@/api/index';
export default {
	data() {
		return {
			showCourse: false,
			/**查询 */
			searchForm: {
				name: ''
			},
			/**表格 */
			tableData: [],
			limit: 10,
			page: 1,
			total: 0,
			loading1: false,
			selectList: [],
			/**入群详情 */
			tabNum: 0,
			loading2: false,
			detailVisible: false,
			groupList: [],
			page1: 1,
			limit1: 8,
			total1: 0,
			tableData1: [],
			loading3: false
		};
	},
	watch: {
		tabNum(val) {
			if (val && val != '0') {
				this.loading3 = true;
				reqGetCustomGroupInfo({
					id: val,
					page: this.page1,
					limit: this.limit1
				}).then(res => {
					this.loading3 = false;
					this.tableData1 = res.data.list;
					this.total1 = res.count || 0;
				});
			}
		},
		'searchForm.name': {
			handler(val) {
				if (!val) {
					this.page = 1;
					this.getDataList();
				}
			},
			deep: true
		},
		total(val) {
			if (val > 0 && this.tableData.length === 0) {
				this.page = parseInt(val / this.limit) + (val % this.limit > 0 ? 1 : 0);
				this.getDataList();
			}
		}
	},
	created() {
		this.getDataList();
	},
	methods: {
		/**查询 */
		onSearch() {
			if (this.searchForm.name) {
				this.page = 1;
				this.getDataList();
			}
		},

		/**表格 */
		getDataList() {
			this.loading1 = true;
			reqAutoCustomGroupList({
				name: this.searchForm.name,
				limit: this.limit,
				page: this.page,
				staff_name: this.searchForm.staff_name
			}).then(res => {
				this.loading1 = false;
				this.total = res.count || 0;
				this.tableData = res.data;
			});
		},
		handleSelectionChange(list) {
			this.selectList = list;
		},
		// 删除自动入群
		handleDel(row) {
			this.$confirm(`确认删除客户群【${row.name}】?`, '删除客户群', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$message({
						center: true,
						iconClass: 'el-icon-loading ',
						duration: 0,
						message: '删除中...'
					});
					reqDelAutoGroup({
						id: row.id
					}).then(res => {
						this.$message.success(res.msg);
						this.getDataList();
					});
				})
				.catch(() => {});
		},
		handleEditQrcode(id) {
			this.$router.push({
				path: '/sales_tools/in_group/create',
				query: {
					id
				}
			});
		},
		/**分页 */
		handleLimitChange(limit) {
			this.limit = limit;
			this.getDataList();
		},
		handlePageChange(page) {
			this.page = page;
			this.getDataList();
		},
		/**按钮 */
		handleCreatQrcode() {
			this.$router.push({
				path: '/sales_tools/in_group/create'
			});
		},
		// 批量下载
		handleBatchDownload() {
			this.selectList.forEach(item => {
				this.downloadIamge(item.qr_code, item.name);
			});
		},
		// 图片下载
		downloadIamge(imgsrc, name) {
			//下载图片地址和图片名
			let image = new Image();
			// 解决跨域 Canvas 污染问题
			image.setAttribute('crossOrigin', 'anonymous');
			image.onload = function() {
				let canvas = document.createElement('canvas');
				canvas.width = image.width;
				canvas.height = image.height;
				let context = canvas.getContext('2d');
				context.drawImage(image, 0, 0, image.width, image.height);
				let url = canvas.toDataURL('image/png'); //得到图片的base64编码数据
				let a = document.createElement('a'); // 生成一个a元素
				let event = new MouseEvent('click'); // 创建一个单击事件
				a.download = name || 'photo'; // 设置图片名称
				a.href = url; // 将生成的URL设置为a.href属性
				a.dispatchEvent(event); // 触发a的单击事件
			};
			image.src = imgsrc;
		},
		/**入群详情 */
		beforeClose() {
			this.detailVisible = false;
		},
		handleDetail(id) {
			this.detailVisible = true;
			this.loading2 = true;
			reqAutoCustomGroupInfo({
				id
			}).then(res => {
				this.loading2 = false;
				if (res.data.length != 0) {
					this.groupList = res.data;
					this.tabNum = res.data[0].id;
				} else {
					this.groupList = [];
					this.tabNum = 0;
				}
			});
		},
		handleCheckTab(data) {}
	}
};
</script>

<style lang="less" scoped>
.in-group {
	width: 100%;
	overflow: hidden;

	.table {
		.flex-btns {
			display: flex;
			flex-wrap: wrap;

			> .el-button {
				margin: 0 5px;
			}
		}
	}

	.inputs {
		margin-top: 10px;
	}

	.btns {
		display: flex;
		align-items: center;
	}

	.flex-wrap {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		span {
			line-height: 1;
			color: #6881ec;
			background: #eef2fe;
			border-radius: 4px;
			border: 1px solid #6881ec;
			margin: 3px;
			padding: 5px;
			font-size: 12px;
		}
	}

	.el-tag {
		margin: 5px 5px 5px 0;
	}
}
</style>
